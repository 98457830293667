import React from 'react';
import "bootstrap/scss/bootstrap.scss";
import './Assets/app.scss';

import HomePage from './Pages/home';

function App() {
  return (
    <div className="App">
      <HomePage />
    </div>
  );
}

export default App;

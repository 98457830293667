import { Checkbox, Input, InputNumber, Radio, Form, Button } from "antd";
import React from "react";

const BasicWidget: React.FC = (props) => {
    return <>
        <Form
            onFinish={(values) => console.log(values)}
        >
            <div className="app-config">
                <div className="title">Tự đánh</div>
                <Form.Item className="mb-0" name={"1"}>
                    <Checkbox.Group className="row">
                        <Checkbox value={1} className="col-6 me-0 ms-0 mb-1">Tự động đánh</Checkbox>
                        <Checkbox value={2} className="col-6 me-0 ms-0 mb-1">Lùa quái</Checkbox>
                        <Checkbox value={3} className="col-6 me-0 ms-0 mb-1">Quanh [-1, -1]</Checkbox>
                        <Checkbox value={4} className="col-6 me-0 ms-0 mb-1">Đánh theo key</Checkbox>
                        <Checkbox value={5} className="col-6 me-0 ms-0">Dùng F1</Checkbox>
                    </Checkbox.Group>
                </Form.Item>
            </div>
            <div className="app-config pt-4 mb-2">
                <div className="title">Hồi phục</div>

                <Form.Item className="mb-0" name={"2"}>
                    <Checkbox.Group className="w-100">
                        <div className="row g-1 w-100">
                            <div className="col-6 pe-2">
                                <div className="d-flex align-items-center justify-content-between">
                                    <Checkbox value={1} className="m-0 mb-1">
                                        Item HP
                                    </Checkbox>
                                    <Form.Item className="mb-0" name={"num_1"}>
                                        <InputNumber size="small" style={{ width: "40px" }} />
                                        %
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Checkbox value={2} className="m-0 mb-1">
                                        Item MP
                                    </Checkbox>
                                    <Form.Item className="mb-0">
                                        <InputNumber size="small" style={{ width: "40px" }} />
                                        %
                                    </Form.Item>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <Checkbox value={3} className="m-0">
                                        Cảnh báo HP
                                    </Checkbox>
                                    <Form.Item className="mb-0 text-nowrap">
                                        <InputNumber size="small" style={{ width: "40px" }} />
                                        %
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-6 d-flex align-items-end justify-content-end">
                                <div className="app-config m-0 pt-3">
                                    <div className="title">
                                        <Checkbox value={4}>Sau khi tử vong</Checkbox>
                                    </div>
                                    <Form.Item name="3" className="mb-0">
                                        <Radio.Group >
                                            <div className="d-flex align-items-center justify-content-between">
                                                <Radio value={1} className="m-0 mb-1 text-nowrap">Thoát gan</Radio>
                                                <Form.Item className="mb-0 text-nowrap">
                                                    <InputNumber size="small" style={{ width: "38px" }} />
                                                    Giây
                                                </Form.Item>
                                            </div>
                                            <Radio value={2} className="m-0">Quay về vị trí cũ</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Checkbox.Group>
                </Form.Item>
            </div>
        </Form>
    </>
}

export default BasicWidget;
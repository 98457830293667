import { faCalendarAlt, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Image, Tabs } from "antd";
import React from "react";
import BasicWidget from "./Widgets/basic";

const HomePage: React.FC = (props) => {
    return <React.Fragment>
        <header>
            <div className="logo">
                <Image preview={false} src="/logo.png" 

                />
            </div>
            <div >
                <Button className="me-2"><FontAwesomeIcon icon={faRotateLeft} className="icon"/> Về game</Button>
                <Button><FontAwesomeIcon icon={faCalendarAlt} className="icon"/> License</Button>
            </div>
        </header>
        <div className="app-body">
            <Tabs type="card" className="tab-config" size="small" defaultActiveKey="1">
                <Tabs.TabPane tab="Cơ bản" key="1">
                    <BasicWidget />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Kỹ năng" key="2" >
                    <BasicWidget />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tiện ích" key="3" >
                    <BasicWidget />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Nhiệm vụ" key="4" >
                    <BasicWidget />
                </Tabs.TabPane>
            </Tabs>
        </div>
        <div className="app-footer pb-2">
                <Button>Hủy bỏ</Button>
                <Button type="primary">Về mặc định</Button>
                <Button type="primary">Lưu thiết lập</Button>
            </div>
    </React.Fragment>
}

export default HomePage